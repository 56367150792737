@import 'shared/reset.scss';
@import 'shared/fonts.scss';

* {
    touch-action: pan-x pan-y;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
::-webkit-scrollbar {
    display: none;
}
html,
body {
    user-select: none;
    overflow: hidden;
}
body {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
    background-color: #ffffff;
    font-family: 'Roboto', serif;
    font-weight: 600;
    color: #000000;
}
#root {
    position: relative;
    height: 100vh;
}
