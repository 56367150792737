.App {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: all;
    background-image: url('/merlijn.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .App_status {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: flex;
            margin: 0 -5px;
        }
        p {
            font-size: 100px;
        }
        &.App_yes p {
            color: #00ac7a;
        }
        &.App_no p {
            color: #ff5353;
        }
        @media only screen and (max-width: 568px) {
            transform: scale(0.75);
        }
    }
}
